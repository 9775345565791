/* common background setting */
.background {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-color: black;
  background-size: cover;
}

/* vectors of maps */
.move-vec {
  position: absolute;
  width:3em;
  height:5em;
  text-align: center;
}

.move-vec img {
  width:3em;
  height:3em;
  opacity:0.5;
}

.move-vec p {
  opacity: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.move-vec:hover img{
  opacity: 1;
}

.move-vec:hover p{
  background-color: black;
  color: white;
  opacity: 1;
}

.move-left {
  top: 50vh;
  left: 0;
}

.move-right {
  top: 50vh;
  right: 0;
}

.move-up {
  top: 3em;
  left: 50vw;
}

.move-down {
  top: 100vh;
  left: 50vw;
  transform: translate(-50%, -100%);
}

/* serif-modal */
.serif-modal .modal-content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0);
}

/* common header parts*/
.header-belt {
  width: 100%;
  height: 25vh;
  overflow: hidden;
  position: relative;
  display: flex;

  background-image:
  radial-gradient(circle at 100% 150%, silver 24%, white 24%, white 28%, silver 28%, silver 36%, white 36%, white 40%, transparent 40%, transparent),
  radial-gradient(circle at 0    150%, silver 24%, white 24%, white 28%, silver 28%, silver 36%, white 36%, white 40%, transparent 40%, transparent),
  radial-gradient(circle at 50%  100%, white 10%, silver 10%, silver 23%, white 23%, white 30%, silver 30%, silver 43%, white 43%, white 50%, silver 50%, silver 63%, white 63%, white 71%, transparent 71%, transparent),
  radial-gradient(circle at 100% 50%, white 5%, silver 5%, silver 15%, white 15%, white 20%, silver 20%, silver 29%, white 29%, white 34%, silver 34%, silver 44%, white 44%, white 49%, transparent 49%, transparent),
  radial-gradient(circle at 0    50%, white 5%, silver 5%, silver 15%, white 15%, white 20%, silver 20%, silver 29%, white 29%, white 34%, silver 34%, silver 44%, white 44%, white 49%, transparent 49%, transparent);
  background-size: 100px 50px;

}

/* clouds scroll horizontally and loop  */
.cloud {
  width: auto;
  height: 100%;
  object-fit: contain;
}

#cloud0 {
  animation: loop0 25s -25s linear infinite;
}

#cloud1 {
  animation: loop0 25s -20s linear infinite;
}
#cloud2 {
  animation: loop0 25s -15s linear infinite;
}
#cloud3 {
  animation: loop0 25s -10s linear infinite;
}
#cloud4 {
  animation: loop0 25s -5s linear infinite;
}

@keyframes loop0 {
  0% {
    transform: translateX(100vw);
  }
  100% {
    transform: translateX(-300vw);
  }
}

@keyframes loop1 {
  0% {
    transform: translateX(120vw);
  }
  100% {
    transform: translateX(-240vw);
  }
}

@keyframes loop2 {
  0% {
    transform: translateX(140vw);
  }
  100% {
    transform: translateX(-200vw);
  }
}

@keyframes loop3 {
  0% {
    transform: translateX(160vw);
  }
  100% {
    transform: translateX(-160vw);
  }
}

@keyframes loop4 {
  0% {
    transform: translateX(180vw);
  }
  100% {
    transform: translateX(-120vw);
  }
}

.outer-diamond {
  position: absolute;
  left:50%;
  transform:translate(-50%,-50%);
}

.header-diamond {
  width: 10em;
  height: 10em;
  transform: rotate(45deg);
  background-color: goldenrod;
  border: goldenrod 1px solid;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.header-diamond div {
  position: absolute;
  transform: rotate(-45deg);
}

.header-diamond hr {
  border-color: white;
}

.header-diamond h1 {
  font-size: xx-large;
  padding: 0 0.5em;
  color: white;
}

.header-diamond p {
  text-align: center;
  color: whitesmoke;
}

/* loading screen disapear when loaded */
.loaded {
  opacity: 0;
  visibility: hidden;
}


/* everywhere else */
* {
  font-family: 'Book Antiqua', 'Times New Roman', 'Century';
}
